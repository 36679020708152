import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default {
    LOAD_TAGS(context) {
        return loadTags(context);
    },

    ADD_TAG(context, payload) {
        return addTag(context, payload);
    },

    REMOVE_TAG(context, payload) {
        return removeTag(context, payload);
    },

    EDIT_TAG(context, payload) {
        return editTag(context, payload);
    },
};

const loadTags = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('tags').get()
        .then((querySnapshot) => {
            const data = [];

            querySnapshot.forEach((doc) => {
                const d = doc.data();

                data.push({ ...d, id: doc.id });
            });

            data.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;

                return 0;
            });

            commit('SET_TAGS', data);

            return data;
        });
};

const addTag = ({ dispatch }, payload) => {
    const db = firebase.firestore();

    return db.collection('tags').doc().set(payload)
        .then(() => {
            dispatch('LOAD_TAGS');
        })
        .catch((e) => { throw e; });
};

const removeTag = ({ commit }, payload) => {
    const db = firebase.firestore();

    return db.collection('tags').doc(payload).delete()
        .then(() => {
            commit('REMOVE_TAG', payload);
        })
        .catch((e) => { throw e; });
};

const editTag = ({ commit }, payload) => {
    const db = firebase.firestore();

    return db.collection('tags').doc(payload.id).set(payload)
        .then(() => {
            commit('SET_TAG', payload);
        })
        .catch((e) => { throw e; });
};
