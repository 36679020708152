export default {
    SET_TAGS(state, payload) {
        state.tags = payload;
    },

    REMOVE_TAG(state, payload) {
        const found = state.tags.findIndex(({ id }) => id === payload);

        if (found >= 0) {
            state.tags.splice(found, 1);
        }
    },

    EDIT_TAG(state, payload) {
        const found = state.tags.findIndex(({ id }) => id === payload.id);

        if (found >= 0) {
            state.tags.splice(found, 1, payload);
        }
    },
};
