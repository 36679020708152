import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import global from '@/store/global';
import auth from '@/store/auth';
import users from '@/store/users';
import hackathons from '@/store/hackathons';
import projects from '@/store/projects';
import tags from '@/store/tags';
import votes from '@/store/votes';

Vue.use(Vuex);

export const modules = {
    global,
    auth,
    users,
    hackathons,
    projects,
    tags,
    votes,
};

const vuexPersistPaths = [
    'auth.googleToken',
    'auth.userId',
    'auth.user',
];

export default new Vuex.Store({
    modules,
    plugins: [
        createPersistedState({
            key: process.env.VUE_APP_FIREBASE_PROJECT_ID,
            paths: vuexPersistPaths,
        }),
    ],
});
