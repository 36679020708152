export default {
    getProjectsByHackathon: ({ projects }) => (id) => {
        if (projects && projects.length) {
            return projects.filter(({ hackathonId }) => hackathonId === id);
        }

        return [];
    },

    getProjectsByUser: ({ projects }) => (uid) => {
        if (projects && projects.length) {
            return projects.filter(({ teamLead, members }) => {
                return teamLead === uid || members.some((member) => member === uid);
            });
        }

        return [];
    },

    getProject: ({ projects }) => (projectId) => {
        if (projects && projects.length) {
            return projects.find(({ id }) => projectId === id) || {};
        }

        return [];
    },

    getWinners(state, { getProject }, { hackathons: rootHackathons }) {
        const winners = [];
        const { hackathons } = rootHackathons;

        hackathons.forEach((hackathon) => {
            if (hackathon.awards) {
                hackathon.awards.forEach((award) => {
                    if (award.winner) {
                        winners.push({ hackathon, award, project: getProject(award.winner) });
                    }
                });
            }
        });

        return winners;
    },

    getAwardByProject: (state, { getWinners }) => (projectId) => {
        return getWinners.find(({ project }) => projectId === project.id) || { project: {}, award: {} };
    },

    commentsByDate({ comments }) {
        return comments.sort((a, b) => new Date(a.dateAdded) - new Date(b.dateAdded));
    },

    sortedProjects({ projects }) {
        return projects?.sort((a, b) => {
            if (a.createdDate > b.createdDate) return 1;
            if (a.createdDate < b.createdDate) return -1;

            return 0;
        }) || [];
    },
};
