export default {
    guestsList({ guests }) {
        return guests?.map(({ email }) => email);
    },

    isGuest({ guests, user }) {
        if (!user.uid) return false;

        return guests;
    },

    getUsers({ users }) {
        if (users && users.length) {
            return users.map(({ uid, name }) => ({ value: uid, label: name }));
        }

        return [];
    },

    getUser: ({ users }) => (id) => {
        if (users && users.length) {
            return users.find(({ uid }) => uid === id) || {};
        }

        return {};
    },
};
