import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

export default {
    LOAD_HACKATHON_COMMENTS(context, projectId) {
        return loadHackathonComments(context, projectId);
    },

    LOAD_HACKATHON_DEMO(context, projectId) {
        return loadHackathonDemo(context, projectId);
    },

    SET_HACKATHON_DEMO(context, payload) {
        return setHackathonDemo(context, payload);
    },

    LOAD_HACKATHONS(context) {
        return loadHackathons(context);
    },

    LOAD_HACKATHON(context, payload) {
        return loadHackathon(context, payload);
    },

    ADD_HACKATHON(context, payload) {
        return addHackathon(context, payload);
    },

    ADD_THUMBNAIL(context, payload) {
        return addThumbnail(context, payload);
    },

    ADD_PHOTO(context, payload) {
        return addPhoto(context, payload);
    },

    EDIT_HACKATHON(context, payload) {
        return editHackathon(context, payload);
    },

    DELETE_HACKATHON(context, payload) {
        return deleteHackathon(context, payload);
    },
};

const addHackathon = ({ dispatch, rootState }, payload) => {
    const { uid } = rootState.auth.user;
    const db = firebase.firestore();
    const hackathon = { createdBy: uid, updatedBy: uid, ...payload };

    return db.collection('hackathons').doc().set(hackathon)
        .then(() => {
            dispatch('LOAD_HACKATHONS');
        })
        .catch((e) => { throw e; });
};

const setHackathonDemo = ({ dispatch }, payload) => {
    const db = firebase.firestore();

    return db.collection('sync').doc('demo').set(payload)
        .then(() => {
            dispatch('LOAD_HACKATHON_DEMO');
        })
        .catch((e) => { throw e; });
};

const loadHackathonComments = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('comments').get()
        .then(({ docs }) => {
            const comments = docs.map((comment) => ({ projectId: comment.id, ...comment.data() }));

            commit('SET_HACKATHON_COMMENTS', comments);

            return comments;
        })
        .catch((e) => { throw e; });
};

const loadHackathonDemo = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('sync').doc('demo')
        .onSnapshot((doc) => {
            if (!doc.exists) return null;

            const demo = doc.data();

            commit('SET_HACKATHON_DEMO', demo);

            return demo;
        });
};

const editHackathon = ({ commit, rootState }, payload) => {
    const { uid } = rootState.auth.user;
    const db = firebase.firestore();
    const hackathon = { updatedBy: uid, ...payload };

    return db.collection('hackathons').doc(payload.id).set(hackathon)
        .then(() => {
            commit('SET_HACKATHON', hackathon);

            return hackathon;
        })
        .catch((e) => { throw e; });
};

const addThumbnail = ({ dispatch, state }, { id, file }) => {
    const { hackathon } = state;
    const storageRef = firebase.storage().ref();
    const imageUrl = `hackathon/${id}/${file.name}`;

    return storageRef.child(imageUrl).put(file)
        .then(() => {
            storageRef.child(imageUrl).getDownloadURL().then((url) => {
                dispatch('EDIT_HACKATHON', { ...hackathon, image: url });
            });

            return hackathon;
        })
        .catch((e) => { throw e; });
};

const addPhoto = ({ dispatch, state }, { id, file }) => {
    const { hackathon } = state;
    const storageRef = firebase.storage().ref();
    const imageUrl = `hackathon/${id}/${file.name}`;

    return storageRef.child(imageUrl).put(file)
        .then(() => {
            storageRef.child(imageUrl).getDownloadURL().then((url) => {
                if (!hackathon.photos) hackathon.photos = [];
                dispatch('EDIT_HACKATHON', { ...hackathon, photos: [url, ...hackathon.photos] });
            });

            return hackathon;
        })
        .catch((e) => { throw e; });
};

const loadHackathon = ({ commit }, id) => {
    const db = firebase.firestore();

    return db.collection('hackathons').doc(id).get()
        .then((doc) => {
            if (!doc.exists) return null;

            const data = doc.data();

            const hackathon = {
                ...data,
                startDate: new Date(data.startDate.seconds * 1000),
                endDate: new Date(data.endDate.seconds * 1000),
            };

            commit('SET_HACKATHON', hackathon);

            return hackathon;
        })
        .catch((e) => { throw e; });
};

const deleteHackathon = ({ commit }, id) => {
    const db = firebase.firestore();

    return db.collection('hackathons').doc(id).delete()
        .then(() => {
            commit('REMOVE_HACKATHON', id);
        })
        .catch((e) => { throw e; });
};

const loadHackathons = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('hackathons').get()
        .then(({ docs }) => {
            const hackathons = docs.map((doc) => {
                const hackathon = doc.data();

                return {
                    id: doc.id,
                    ...hackathon,
                    startDate: new Date(hackathon.startDate.seconds * 1000),
                    endDate: new Date(hackathon.endDate.seconds * 1000),
                };
            });

            commit('SET_HACKATHONS', hackathons);

            return hackathons;
        })
        .catch((e) => { throw e; });
};
