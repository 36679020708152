import axios from 'axios';
import store from '@/store';

export default {
    setup() {
        axios.interceptors.request.use((config) => {
            const { googleToken } = store.state.auth;
            const hasToken = Boolean(googleToken);

            if (hasToken) {
                config.headers.Authorization = `Bearer ${googleToken}`;
            }

            return config;
        });

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response && error.response.status === 403) {
                window.location = '/forbidden';
            }

            const unauthenticatedCount = store.state.auth.unauthenticatedCount || 0;

            if (error.response && error.response.status === 401) {
                if (unauthenticatedCount < 3) {
                    window.localStorage.clear();
                    store.commit('auth/SET_UNAUTHENTICATED_COUNT', unauthenticatedCount + 1);
                    window.location = '/login';
                } else if (unauthenticatedCount >= 3) {
                    window.localStorage.clear();
                    store.commit('auth/SET_UNAUTHENTICATED_COUNT', 0);
                    window.location = '/forbidden';
                }
            }

            return Promise.reject(error);
        });
    },
};
