export default {
    SET_OVERLAY_ACTIVE(state, active) {
        checkOverlay(state, active, 'overlay-active');
    },
};

const checkOverlay = (state, active, className) => {
    if (active) {
        state.overlaysActive++;
    } else if (state.overlaysActive > 0) {
        state.overlaysActive--;
    }

    setTimeout(() => {
        if (state.overlaysActive > 0) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    });
};
