export default {
    SET_USERS(state, payload) {
        state.users = payload;
    },

    SET_GUESTS(state, guests) {
        state.guests = guests;
    },

    SET_USER(state, payload) {
        state.user = payload;
    },

    SAVE_GITHUB_HANDLE(state, payload) {
        state.gitHubHandle = payload;
    },
};
