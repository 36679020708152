import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default {
    LOAD_PROJECTS(context) {
        return loadProjects(context);
    },

    LOAD_PROJECT({ commit }, projectId) {
        const db = firebase.firestore();

        return db.collection('projects').doc(projectId).get()
            .then((doc) => {
                const project = { id: doc.id, ...doc.data() };

                commit('SET_PROJECT', project);

                return project;
            })
            .catch((e) => { throw e; });
    },

    CREATE_PROJECT(context, project) {
        return createProject(context, project);
    },

    DELETE_PROJECT(context, projectId) {
        return deleteProject(context, projectId);
    },

    SAVE_PROJECT(context, project) {
        return saveProject(context, project);
    },

    LOAD_PROJECT_COMMENTS(context, projectId) {
        return loadProjectComments(context, projectId);
    },

    SAVE_COMMENTS(context, payload) {
        return saveComments(context, payload);
    },

    ADD_PROJECT_THUMBNAIL(context, payload) {
        return addProjectThumbnail(context, payload);
    },

    ADD_PROJECT_PHOTO(context, payload) {
        return addProjectPhoto(context, payload);
    },
};

const loadProjectComments = ({ commit }, projectId) => {
    const db = firebase.firestore();

    return db.collection('comments').doc(projectId).get()
        .then((response) => {
            const comments = response.data()
                ? response.data().comments
                : [];

            commit('SET_PROJECT_COMMENTS', comments);

            return comments;
        })
        .catch((e) => { throw e; });
};

const saveComments = ({ commit }, { projectId, comments }) => {
    const db = firebase.firestore();

    return db.collection('comments').doc(projectId).set({ comments })
        .then(() => {
            commit('SET_PROJECT_COMMENTS', comments);
        })
        .catch((e) => { throw e; });
};

const createProject = ({ commit, dispatch }, project) => {
    const db = firebase.firestore();

    db.collection('projects').doc().set(project)
        .then(() => {
            commit('SET_PROJECT', project);
            dispatch('LOAD_PROJECTS');
        })
        .catch((e) => { throw e; });
};

const deleteProject = (context, projectId) => {
    const db = firebase.firestore();

    db.collection('projects').doc(projectId).delete()
        .catch((e) => { throw e; });
};

const saveProject = ({ commit }, { projectId, project }) => {
    const db = firebase.firestore();

    return db.collection('projects').doc(projectId).set(project, { merge: true })
        .then(() => {
            commit('SET_PROJECT', project);
        })
        .catch((e) => { throw e; });
};

const loadProjects = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('projects').get()
        .then(({ docs }) => {
            const projects = docs.map((project) => {
                return {
                    id: project.id,
                    ...project.data(),
                };
            });

            commit('SET_PROJECTS', projects);

            return projects;
        })
        .catch((e) => { throw e; });
};

const addProjectThumbnail = ({ dispatch }, { file, project }) => {
    return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref();
        const imageUrl = `projects/${project.id}/thumbnail/${file.name}`;

        storageRef.child(imageUrl).put(file)
            .then(() => {
                storageRef.child(imageUrl).getDownloadURL().then((thumbnail) => {
                    dispatch('SAVE_PROJECT', {
                        projectId: project.id,
                        project: {
                            ...project,
                            thumbnail,
                        },
                    });

                    resolve(thumbnail);
                });
            })
            .catch((e) => reject(e));
    });
};

const addProjectPhoto = ({ dispatch, state }, file) => {
    const storageRef = firebase.storage().ref();
    const { project } = state;
    const imageUrl = `projects/${project.id}/photos/${file.name}`;

    return storageRef.child(imageUrl).put(file)
        .then(() => {
            storageRef.child(imageUrl).getDownloadURL().then((photoUrl) => {
                const { photos } = project;

                photos.push(photoUrl);

                const updatedProject = { ...project, photos };

                dispatch('SAVE_PROJECT', {
                    projectId: project.id,
                    project: updatedProject,
                });
            });
        })
        .catch((e) => { throw e; });
};
