export default {
    SET_UNAUTHENTICATED_COUNT(state, count) {
        state.unauthenticatedCount = parseInt(count, 10);
    },

    SET_USER_ID(state, userId) {
        state.userId = userId;
    },

    SET_USER(state, user) {
        state.user = user;
        state.userId = user ? user.email : '';
    },

    SET_GOOGLE_TOKEN(state, token) {
        state.googleToken = token;
    },
};
