export default {
    getVotesByHackathon: ({ votes }) => (id) => {
        if (votes && votes.length) {
            return votes.filter(({ hackathonId }) => hackathonId === id);
        }

        return [];
    },

    getVotesByHackathonAward: ({ votes }) => (id, awardIndex) => {
        if (votes && votes.length) {
            return votes.filter(({ hackathonId, awardId }) => hackathonId === id && awardId === awardIndex);
        }

        return [];
    },

    getUserVote: ({ votes }) => ({ hackathonId, userId, awardId }) => {
        return votes?.length
            ? votes?.find((vote) => hackathonId === vote?.hackathonId && userId === vote?.userId && awardId === vote?.awardId) || null
            : null;
    },
};
