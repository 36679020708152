const HomePage = () => import(/* webpackChunkName: "public" */ '@/pages/HomePage');
const DemoPage = () => import(/* webpackChunkName: "main" */ '@/pages/DemoPage');
const GuestsPage = () => import(/* webpackChunkName: "admin" */ '@/pages/GuestsPage');
const HackathonPage = () => import(/* webpackChunkName: "main" */ '@/pages/HackathonPage');
const VotesPage = () => import(/* webpackChunkName: "admin" */ '@/pages/VotesPage');
const UsersPage = () => import(/* webpackChunkName: "admin" */ '@/pages/UsersPage');
const EditHackathonPage = () => import(/* webpackChunkName: "main" */ '@/pages/EditHackathonPage');
const ProjectsPage = () => import(/* webpackChunkName: "main" */ '@/pages/ProjectsPage');
const IdeasPage = () => import(/* webpackChunkName: "main" */ '@/pages/IdeasPage');
const IdeaPage = () => import(/* webpackChunkName: "main" */ '@/pages/IdeaPage');
const ProjectPage = () => import(/* webpackChunkName: "main" */ '@/pages/ProjectPage');
const EditProjectPage = () => import(/* webpackChunkName: "main" */ '@/pages/EditProjectPage');
const UserPage = () => import(/* webpackChunkName: "main" */ '@/pages/UserPage');
const EditUserPage = () => import(/* webpackChunkName: "main" */ '@/pages/EditUserPage');
const SearchPage = () => import(/* webpackChunkName: "project" */ '@/pages/SearchPage');
const HackathonVotePage = () => import(/* webpackChunkName: "main" */ '@/pages/HackathonVotePage');
const AdminVotesPage = () => import(/* webpackChunkName: "admin" */ '@/pages/AdminVotesPage');
const HallOfFamePage = () => import(/* webpackChunkName: "main" */ '@/pages/HallOfFamePage');
const VotesChartPage = () => import(/* webpackChunkName: "admin" */ '@/pages/VotesChartPage');
const TagsPage = () => import(/* webpackChunkName: "admin" */ '@/pages/TagsPage');
const EditTagPage = () => import(/* webpackChunkName: "admin" */ '@/pages/EditTagPage');
const NotFoundPage = () => import(/* webpackChunkName: "public" */ '@/pages/NotFoundPage');
const LoginPage = () => import(/* webpackChunkName: "public" */ '@/pages/LoginPage');
const ForbiddenPage = () => import(/* webpackChunkName: "public" */ '@/pages/ForbiddenPage');

export default [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        meta: {
            public: true,
        },
    },
    { path: '/demo', name: 'demo', component: DemoPage },
    { path: '/hackathon/new', name: 'new.hackathon', component: EditHackathonPage },
    { path: '/hackathon/:id/edit', name: 'edit.hackathon', component: EditHackathonPage },
    { path: '/hackathon/:id/vote', name: 'hackathon.vote', component: HackathonVotePage },
    { path: '/hackathon/:id/votes', name: 'votes-chart', component: VotesChartPage },
    { path: '/hackathon/:id', name: 'hackathon', component: HackathonPage },
    { path: '/projects/:id/edit', name: 'projects.edit', component: EditProjectPage },
    { path: '/projects/new', name: 'projects.new', component: EditProjectPage },
    { path: '/projects/:id', name: 'project', component: ProjectPage },
    { path: '/projects', name: 'projects', component: ProjectsPage },
    { path: '/ideas', name: 'ideas', component: IdeasPage },
    { path: '/tags/new', name: 'tags.new', component: EditTagPage },
    { path: '/tags/:id', name: 'tags.edit', component: EditTagPage },
    { path: '/tags', name: 'tags', component: TagsPage },
    { path: '/ideas/new', name: 'ideas.new', component: EditProjectPage },
    { path: '/ideas/:id/edit', name: 'ideas.edit', component: EditProjectPage },
    { path: '/ideas/:id', name: 'idea', component: IdeaPage },
    { path: '/user/:id/edit', name: 'user.edit', component: EditUserPage },
    { path: '/user/:id', name: 'user', component: UserPage },
    { path: '/search', name: 'search', component: SearchPage },
    { path: '/hall-of-fame', name: 'hall.of.fame', component: HallOfFamePage },
    { path: '/admin/votes/:id', name: 'admin-votes', component: AdminVotesPage },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            public: true,
        },
    },
    { path: '/forbidden', name: 'forbidden', component: ForbiddenPage },
    { path: '/votes', name: 'votes', component: VotesPage },
    { path: '/users', name: 'users', component: UsersPage },
    { path: '/guests', name: 'guests', component: GuestsPage },
    { path: '*', component: NotFoundPage },
];
