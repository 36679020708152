export default {
    SET_HACKATHONS(state, hackathons) {
        state.hackathons = hackathons.sort((a, b) => {
            if (a.startDate > b.startDate) return -1;
            if (a.startDate < b.startDate) return 1;

            return 0;
        });
    },

    SET_HACKATHON_DEMO(state, demo) {
        state.demo = demo;
    },

    SET_HACKATHON(state, payload) {
        state.hackathon = payload;

        const found = state.hackathons.findIndex(({ id }) => id === payload.id);

        if (found >= 0) {
            state.hackathons.splice(found, 1, payload);
        }
    },

    SET_HACKATHON_COMMENTS(state, comments) {
        state.comments = comments;
    },

    CLEAR_HACKATHON(state) {
        state.hackathon = {};
    },

    REMOVE_HACKATHON(state, payload) {
        const found = state.hackathons.findIndex(({ id }) => id === payload);

        if (found >= 0) {
            state.hackathons.splice(found, 1);
        }
    },
};
