import moment from 'moment';

export default {
    getActiveHackathon({ hackathons }) {
        return hackathons?.find(({ endDate }) => moment().isBefore(moment(endDate)));
    },

    winningProjectIds({ hackathons }) {
        const winningProjectIds = hackathons
            ?.filter((hackathon) => hackathon?.awards?.length)
            ?.map(({ awards }) => awards?.filter(({ winner }) => winner)?.map(({ winner }) => winner))
            ?.flat();

        return [...new Set(winningProjectIds)];
    },

    getHackathons({ hackathons }) {
        return hackathons?.map(({ id, name }) => ({ value: id, label: name })) || [];
    },

    getHackathon: ({ hackathons }) => (hackId) => {
        if (hackathons && hackathons.length) {
            return hackathons.find(({ id }) => id === hackId) || {};
        }

        return {};
    },
};
