import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default {
    LOAD_VOTES(context) {
        return loadVotes(context);
    },

    ADD_VOTE(context, payload) {
        return addTag(context, payload);
    },
};

const loadVotes = ({ commit }) => {
    const db = firebase.firestore();

    return db.collection('vote').get()
        .then((querySnapshot) => {
            const data = [];

            querySnapshot.forEach((doc) => {
                const d = doc.data();

                data.push(d);
            });

            commit('SET_VOTES', data);

            return data;
        });
};

const addTag = ({ commit }, payload) => {
    const db = firebase.firestore();

    return db.collection('vote').doc().set(payload)
        .then(() => {
            commit('ADD_VOTE', payload);
        })
        .catch((e) => { throw e; });
};
