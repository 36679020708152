export default {
    getTags({ tags }) {
        if (tags && tags.length) {
            return tags.map(({ id, name }) => ({ value: id, label: name }));
        }

        return [];
    },

    getTag: ({ tags }) => (tagId) => {
        if (tags && tags.length) {
            return tags.find(({ id }) => tagId === id) || {};
        }

        return {};
    },
};
