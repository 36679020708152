<template lang="html">
    <div :class="['demo-widget', { 'no-image': !demoProject.thumbnail }]">
        <div class="project-info">
            <img
                v-if="demoProject.thumbnail"
                :src="demoProject.thumbnail"
                class="demo-project-thumbnail"
                @click="openProject"
            />

            <div class="demo-project">
                <DsStatus
                    emphasis="high"
                    type="error"
                    class="pulsate cursor-pointer"
                    @click="openProject"
                >
                    <DsIcon name="circle-fill" style="--icon-color: white; --icon-size: .5rem" />
                    Demo live
                </DsStatus>


                <span class="project-name cursor-pointer" @click="openProject">
                    {{ demoProject.name }}
                </span>

                <small>Project {{ currentProjectIndex + 1 }} of {{ demoProjectIds.length }}</small>
            </div>
        </div>

        <div class="demo-widget-actions">
            <div v-if="isAdmin">
                <DsTooltip :disabled="disablePrevButton">
                    <template #reference>
                        <DsIconButton
                            dense
                            name="chevron-left"
                            :disabled="disablePrevButton"
                            @click="prevProject"
                        />
                    </template>

                    <template #default>
                        Previous project
                    </template>
                </DsTooltip>

                <DsTooltip>
                    <template #reference>
                        <DsIconButton
                            :name="demoHackathonId ? 'square-fill' : 'play-fill'"
                            dense
                            @click="openStopConfirm = true"
                        />
                    </template>

                    <template #default>
                        Stop demo
                    </template>
                </DsTooltip>


                <DsConfirmDialog
                    :is-open="openStopConfirm"
                    title="Are you sure?"
                    message="This will stop the demo for everyone."
                    confirm-label="Yes, stop demo."
                    cancel-label="Cancel"
                    destructive
                    @cancel="openStopConfirm = false"
                    @confirm="stopDemo"
                />

                <DsTooltip :disabled="disableNextButton">
                    <template #reference>
                        <DsIconButton
                            dense
                            name="chevron-right"
                            :disabled="disableNextButton"
                            @click="nextProject"
                        />
                    </template>

                    <template #default>
                        Next project
                    </template>
                </DsTooltip>
            </div>

            <!-- <DsPopover
                :is-open="isOpen"
                style="--popover-width: 320px"
                @close="isOpen = false"
            >
                <template #reference>
                    <DsTooltip :disabled="disablePrevButton">
                        <template #reference>
                            <DsIconButton
                                dense
                                name="list-unordered"
                                @click="isOpen = true"
                            />
                        </template>

                        <template #default>
                            Demo lineup
                        </template>
                    </DsTooltip>
                </template>

                <template #default>
                    <div class="demo-list">
                        <ProjectsList
                            compact
                            :projects="demoProjects"
                            @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
                        />
                    </div>
                </template>
            </DsPopover> -->
        </div>
    </div>
</template>

<script>
// import ProjectsList from '@/components/ProjectsList';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        // ProjectsList,
    },

    data() {
        return {
            openStopConfirm: false,
            isOpen: false,
        };
    },

    computed: {
        ...mapState({
            demo: ({ hackathons }) => hackathons.demo,
            hackathons: ({ hackathons }) => hackathons.hackathons,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        ...mapGetters({
            getProject: 'projects/getProject',
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
        }),

        isDemoPage() {
            return this.$route?.name === 'demo';
        },

        demoProject() {
            return this.getProject(this.demo.projectId);
        },

        disablePrevButton() {
            return this.currentProjectIndex <= 0;
        },

        disableNextButton() {
            return this.currentProjectIndex >= this.demoProjectIds?.length - 1;
        },

        demoHackathonId() {
            return this.demo?.hackathonId;
        },

        demoProjectId() {
            return this.demo?.projectId;
        },

        demoProjectIds() {
            return this.demoProjects.map(({ id }) => id);
        },

        currentProjectIndex() {
            return this.demoProjectIds?.indexOf(this.demoProject?.id);
        },

        demoProjects() {
            const hackathonProjects = this.getProjectsByHackathon(this.demo?.hackathonId);

            return hackathonProjects?.filter(({ disablePresenting }) => !disablePresenting)?.sort((a, b) => {
                if (a.createdDate > b.createdDate) return 1;
                if (a.createdDate < b.createdDate) return -1;

                return 0;
            });
        },
    },

    methods: {
        openProject() {
            if (!this.isDemoPage) this.$router.push({ name: 'demo' });
            // if (!this.isDemoPage) this.$router.push({ name: 'project', params: { id: this.demoProjectId } })
        },

        async stopDemo() {
            const payload = {
                hackathonId: null,
                projectId: null,
                startTime: null,
            };

            await this.$store.dispatch('hackathons/SET_HACKATHON_DEMO', payload);
        },

        prevProject() {
            if (this.currentProjectIndex <= 0) return;

            this.selectProject(this.demoProjects[this.currentProjectIndex - 1]);
        },

        nextProject() {
            if (this.disableNextButton) return;

            this.selectProject(this.demoProjects[this.currentProjectIndex + 1]);
        },

        async selectProject(project) {
            if (!this.isAdmin) return;

            const payload = {
                hackathonId: project.hackathonId,
                projectId: project.id,
                // startTime: moment().format(),
            };

            await this.$store.dispatch('hackathons/SET_HACKATHON_DEMO', payload);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.demo-widget {
    background-color: $color-ink-800;
    display: flex;
    margin-top: auto;
    padding: $spacing-100;
    align-items: center;
    border-radius: $border-radius;
    justify-content: space-between;
    --icon-color: #{$color-gray-700};

    @media($extra-small) {
        display: none;
    }

    &.no-image {
        padding-left: $spacing-200;
    }
}

.demo-project-thumbnail {
    height: 46px;
    border-radius: calc($border-radius / 2);
}

.project-info {
    display: flex;
    align-items: center;
    grid-gap: $spacing-100;
}

.project-name {
    margin-top: $spacing-050;
    color: $color-yellow-300;
    padding-right: $spacing-100;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 200px;
}

.demo-project {
    display: flex;
    color: $color-white;
    flex-direction: column;
}

.demo-list {
    height: 50vh;
    overflow: auto;
    @include custom-scroll-bar;
}

.demo-widget-actions {
    display: flex;
    align-items: center;
    --icon-color: #{$color-gray-500};

    &:hover {
        --icon-color: #{$color-gray-400};
    }
}
</style>
